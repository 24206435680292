import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { tenantResolver } from './resolvers/tenant.resolver';

const routes: Routes = [
    {
        path: 'a',
        loadChildren: () => import('./components/application/application.routing').then((mod) => mod.APPLICATION_ROUTES)
    },
    {
        path: 'e/:tenant',
        loadComponent: () => import('./components/candidates/edit/edit.component').then((x) => x.EditComponent),
        resolve: { tenant: tenantResolver }
    },
    {
        path: 'v/:tenant',
        loadComponent: () => import('./components/candidates/new-video-session/new-video-session.component').then((x) => x.NewVideoSessionComponent),
        resolve: { tenant: tenantResolver }
    },
    {
        path: 'seite-nicht-gefunden',
        loadChildren: () => import('./components/not-found/not-found.routing').then((mod) => mod.NOT_FOUND_ROUTES)
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload',
            initialNavigation: 'enabledBlocking',
            anchorScrolling: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
