import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Tenant } from '../../../services/tenant.service';
import { PrismicDocument } from '@prismicio/client';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {

    @Input({ required: true }) tenant?: PrismicDocument<Tenant>;
}
