<footer class="pt-6 my-5">
    <div class="container">
        <hr />
        <div class="row justify-content-between mt-5">
            <div class="col-9 d-flex align-items-center">
                <div>
                    <a class="me-3" [href]="tenant?.data?.imprint?.url" target="_blank">Impressum</a>
                    <a [href]="tenant?.data?.data_policy?.url" target="_blank">Datenschutz</a>
                </div>
            </div>
            <div class="col-3 text-end">
                <img [src]="tenant?.data?.logo?.url" class="logo" [alt]="tenant?.data?.logo?.alt">
            </div>
        </div>
    </div>
</footer>
