import { Environment } from './environment.model';
import { prismicClient } from '../prismic';

export const environment: Environment = {
    production: true,
    title: 'gotoitcareer DEV',
    baseUrl: 'https://apply-dev.gotoitcareer.com',
    backendUrl: 'https://develop-dot-gotoitcareer.ey.r.appspot.com',
    oldBackendUrl: 'https://develop-dot-gotoitcareer.ey.r.appspot.com',
    prismic: {
        client: prismicClient
    },
}
