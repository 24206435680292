import { ResolveFn } from '@angular/router';
import { Tenant, TenantService } from '../services/tenant.service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { PrismicDocument } from '@prismicio/client';

export const tenantResolver: ResolveFn<Observable<PrismicDocument<Tenant>>> = (route, state) => {
    const tenantService = inject(TenantService);

    return tenantService.getTenant(route.paramMap.get('tenant') || route.data['uid']);
};
