import {AfterViewInit, Component, inject, OnInit} from '@angular/core';
import { GlobalContentService } from './services/global-content.service';
import AOS from 'aos';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    //TODO: REPLACE <any> with correct type

    constructor(private translate: TranslateService) {
        translate.setDefaultLang('de');
    }

    globalContent: any;
    //private readonly globalContentService = inject(GlobalContentService);

    ngOnInit() {
        /*this.globalContentService.getGlobalContent().subscribe((globalContent) => {*/
        /*    if (globalContent) {*/
        /*        this.globalContent = globalContent;*/
        /*    }*/
        /*    AOS.init();*/
        /*});*/
        AOS.init();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            AOS.refresh()
        }, 500)
    }
}
